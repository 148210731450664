.IndividualHeader {
    background: var(--background);
    background-color: var(--background);
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex;
    background-color: #393e46;
    color: #eeeeee;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    align-items: center;
    position:relative;
    z-index: 999;
}

.title-h1 {
    font-size: 5.0rem;
}
.title-h2{
    font-size: 2.5rem;
}

.maincontent {
    max-width: 1000px;
    
}

.mainimg img {
    display: block;
    max-width: 750px;
    max-height: 800px;
    max-width: 80%;
    max-height: 500px;
    margin-left: 10%;
    margin-right: 10%;
    border-radius: 50px;
    margin: 50px;
}

.mainimg {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .mainimg img {
        display: block;
        max-width: 80%;
        max-height: 400px; 
        height: auto;
        /*height: 500px;*/
        border-radius: 50px;
        margin-right: 10%;
        margin-left: 10%;
    }

    .title-h2 {
        padding-bottom: 0;
    }

    .Buttons {
        display: flex;
        /*flex-direction: column;*/
        align-content: center;
        align-items: center;
        padding-bottom: 20px;
    }


}