.pageCardContainer {
    background-color: #393e46;
    color: #eeeeee;
    border-color: #00adb5;
    border-width: thick;
    border-style: solid;
    margin: 20px;
    margin-top: 20px;
    margin-bottom: 20px;

    border-radius: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 50px;
    position:relative;
    z-index: 0;

    flex-grow: 1;
    flex-wrap: nowrap;

}

.cardBody {
    display: flex;
    font-style: normal;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    justify-content:center;
}

@media screen and (max-width: 1060px) {
    .cardBody {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.pageimage img {
    display: inline;
    vertical-align: middle;
    align-items: center;
    max-width: 80%;
    max-height: 250px;
    margin-left: 10%;
    margin-right: 10%;
    border-radius: 50px;
}

@media screen and (max-width: 600px) {

    .pageimage img {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 80%;
        max-height: 250px;
        margin-left: 10%;
        margin-right: 10%;
    }

    .cardTitle h1 {
        /*width: 220px;*/
        font-size: 2rem;
    }

    .cardBody p {
        font-size: 1rem;
        text-align: justify;
        padding-bottom: 20px;
    }

    .maincontent h1 {
        font-size: 3.0rem;
    }

    .maincontent h2 {
        font-size: 1.5rem;
        margin: 20px;
    }

    .pageCardContainer{
        padding-left: 25px;
        padding-right: 25px;
        width: 250px;
    }
}

@media screen and (min-width: 1060px) {
    .pageimage img {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: inherit;
        /*max-width: 250px; */
        /*max-height: 250px; */
        margin-left: 30px;
    }

}

.pageimage {
    display: flex;
    vertical-align: middle;
    align-items: center;

}