.HomeTitleCardContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    margin-top: 13px;
    position: relative;
    z-index: 0;
}

@media screen and (max-width: 1080px) and (min-width: 600px) {

    .HomeTitleCardContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 60px;
        margin-top: 13px;
        align-items: center;
    }

    .cardContainer {
        width: 90%;
        
    }

}

@media screen and (max-width: 600px) {
    .HomeTitleCardContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 60px;
        margin-top: 13px;
        align-items: center;
    }

    .cardContainer {
        width: 90%;
        
    }
}