.buttonLink {
    text-align: center;
    display: block;
    margin: 10px;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.50);
    padding: 15px;
    width: 300px;
    margin-left: 29px;
    margin-right: 29px;
    border-radius: 50px;
}


.title-icons {
    background-color: #393e46;
    width: 125px;
    cursor: pointer;
}

.buttonElement {
    background-color: #393e46;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
}

.buttonLink:hover {
    box-shadow: 0 0px 8px 1px #00f2ff, 0 6px 20px 0 rgba(0, 0, 0, 0.90);
}

@media screen and (max-width: 1080px) and (min-width: 600px) {

    .buttonLink {
        text-align: center;
        display: block;
        margin: 10px;
        box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.50);
        padding: 15px;
        width: 150px;
        /*margin-left: 29px;
        margin-right: 29px; */
        border-radius: 50px;
    }


    .buttonElement .title-icons {
        width: 75px;
    }

}


@media screen and (max-width: 600px) {

    .buttonLink {
        text-align: center;
        display: block;
        margin: 10px;
        box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.50);
        padding: 15px;
        width: 75px;
        /*margin-left: 29px;
        margin-right: 29px; */
        border-radius: 50px;
    }


    .buttonElement .title-icons {
        width: 45px;
    }

}
