h1 {
    text-align: center;
    color: #00adb5;
    font-family: 'Lobster', 'Merriweather Sans', sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
}

.projectOverviewContainer {
    display: flex;
    background-color: #393e46;
    color: #eeeeee;
    border-color: #00adb5;
    border-width: thick;
    border-style: solid;
    padding: 10px;
    margin: 25px;
    margin-top: 40px;

    width: fit-content;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;

}

.projectOverviewimg {
    display: flex;
    justify-content: center;
}

.projectOverviewContainer img {
    max-width: 100%;
    max-height: 100%;
}

.details {
    /*width: 670px;*/
    height: 250px;
    display: flex;
    justify-content: center;
    margin-top: 13px;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.projectOverviewBody {
    font-size: 1.4rem;
    color: #eeeeee;
    text-decoration: none;
    margin-right: 10px;
    width: 350px;

}

.programChipContainer{
    padding-top: 10px;
}

@media screen and (max-width: 600px) {

    .projectOverviewContainer {
        display: flex;
        flex-direction: column;
        background-color: #393e46;
        color: #eeeeee;
        border-color: #00adb5;
        border-width: thick;
        border-style: solid;
        padding: 10px;
        margin: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 40px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 50px;

    }

    .details {
        flex-direction: column;
    }

    img {
        display: flex;
        align-items: center;
        max-width: 250px;
        max-height: 300px;
        /*margin-left: 10%;*/
        /*margin-right: 10%;*/
        border-radius: 50px;
    }

    .projectOverviewTitle h1 {
        font-size: 2.5rem;
    }

    .projectOverviewBody p {
        font-size: 1rem;
        text-align: justify;
        /* padding-bottom: 20px; */
    }

    .projectOverviewBody {
        width: auto;
    }

    .details {

        height: auto;
    }

    .projectOverviewContainer h1 {
        max-width: 230px;
        font-size: 2rem;
    }
    
    .programChipContainer{
        justify-content: center;
    }

}

@media screen and (min-width: 1060px) {
    .details {

        width: 491px;
    }

    img {

        max-width: 400px;
        max-height: 400px;
    }

}


.projectOverviewContainer:hover {
    box-shadow: 0 0px 8px 1px #00adb5, 0 6px 20px 0 rgba(0, 0, 0, 0.90);
}

.projectOverviewTitle {
    color: #00adb5;
    font-size: 2rem;
    text-decoration: underline;
    display: flex;
    justify-content: center;

}


a:-webkit-any-link {
    text-decoration: none;
}

img {
    display: block;
    width: auto;
    height: auto;
    border-radius: 50px;
}